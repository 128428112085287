import React, { forwardRef, useEffect } from 'react'
import styled from 'styled-components'

const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
`

type TProps = {
  value: string
  onChange: (value: string) => void
  onRemoveElement: () => void
  onSearchClick: (open: boolean) => void
  onNavigate: (top: boolean) => void
  onSubmit: () => void
  autoFocus?: boolean
  tabIndex?: number
}

const SearchInput = forwardRef<HTMLInputElement, TProps>(
  (
    {
      value,
      onChange,
      onRemoveElement,
      onSearchClick,
      onNavigate,
      onSubmit,
      autoFocus,
      tabIndex = 0,
    },
    ref,
  ) => {
    const inputRef = ref as React.RefObject<HTMLInputElement>

    useEffect(() => {
      if (autoFocus && inputRef?.current) {
        inputRef.current.focus()
      }
    }, [autoFocus, inputRef])

    return (
      <Input
        ref={ref}
        type="text"
        placeholder="Search..."
        autoFocus={autoFocus}
        value={value}
        tabIndex={tabIndex}
        onClick={(e) => {
          e.stopPropagation()
          onSearchClick(true)
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
          const target: any = e.target

          if (target.value === '' && e.keyCode === 8) {
            onRemoveElement()
          }

          if ([38, 40].includes(e.keyCode)) {
            onNavigate(e.keyCode === 38)
          }

          if (e.keyCode === 13) {
            e.preventDefault()
            onSubmit()
          }

          if ([37, 39].includes(e.keyCode)) {
            onSearchClick(false)
          }
          if (e.keyCode === 40) {
            onSearchClick(true)
          }
        }}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    )
  },
)

export default SearchInput
