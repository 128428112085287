import { DatePicker } from 'antd'
import { useFormikContext } from 'formik'
import React from 'react'

const { RangePicker } = DatePicker

const PeriodField = () => {
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="period">Period :</label>
      <RangePicker
        {...formik.getFieldProps('period')}
        style={{ maxWidth: 300, width: 300, marginRight: '15px' }}
        onChange={(value) => formik.setFieldValue('period', value)}
        size="large"
        id="period"
      />
    </>
  )
}

export default PeriodField
