import { Input } from 'antd'
import { useFormikContext } from 'formik'
import React from 'react'

const HashField = () => {
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="hash">Or search by hash:</label>
      <Input
        id="hash"
        placeholder="Hash (image id)"
        onChange={(e) => formik.setFieldValue('hash', e.target.value)}
        size="large"
        value={formik.values.hash}
      />
    </>
  )
}

export default HashField
