import { Select } from 'antd'
import { BannerStatus } from 'consts'
import { useFormikContext } from 'formik'
import React from 'react'

const { Option } = Select

const StatusField = () => {
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="status">Status :</label>
      <Select
        {...formik.getFieldProps('status')}
        onBlur={() => void 1}
        id="status"
        onChange={(value: number) => {
          formik.setFieldValue('status', value)
          if (value === BannerStatus.APPROVED) {
            formik.setFieldValue('format', null)
            formik.setFieldValue('is_promotion', 0)
            formik.setFieldValue('is_game', 0)
          } else formik.setFieldValue('format', '0')
        }}
        size="large"
        style={{ width: 300 }}
      >
        <Option value={BannerStatus.APPROVED}>Uninserted Banners</Option>
        <Option value={BannerStatus.INSERTED}>Inserted Banners</Option>
        <Option value={BannerStatus.READY_TO_BE_INSERTED}>
          Ready to be Inserted
        </Option>
        <Option value={BannerStatus.PARTIALLY_INSERTED_BRANDS}>
          Partialy Inserted brand
        </Option>
        <Option value={BannerStatus.PARTIALLY_INSERTED_CATEGORIES}>
          Partialy Inserted categories
        </Option>
      </Select>
    </>
  )
}

export default StatusField
