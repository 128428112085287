import api from 'api'
import { mapKeys } from 'lodash'
import { useQuery } from 'react-query'

const useRetailers = (
  country?: number,
  queryKey = [] as string[],
  enabled = true,
) => {
  return useQuery({
    queryKey: [...queryKey, 'retailers', country],
    queryFn: async () => {
      const data = await api.retailers.get(country)
      return mapKeys(data, 'Id') as any
    },
    enabled,
  })
}

export default useRetailers
