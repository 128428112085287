import { Select } from 'antd'
import { useFormikContext } from 'formik'
import React from 'react'

const { Option } = Select

const TypeField = () => {
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="type">Type :</label>
      <Select
        {...formik.getFieldProps('type')}
        onBlur={() => void 1}
        id="type"
        style={{ width: 300 }}
        onChange={(value: number) => formik.setFieldValue('type', value)}
        size="large"
      >
        <Option value={0}>All</Option>
        <Option value={1}>Category</Option>
        <Option value={2}>Search</Option>
      </Select>
    </>
  )
}

export default TypeField
