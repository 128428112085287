import { Button, Table } from 'antd'
import useBannerDetails from 'hooks/useBannerDetails'
import useRetailers from 'hooks/useRetailers'
import moment from 'moment'
import React from 'react'

const { Column } = Table

const BannerDetailsTable: React.FC<{
  image_hash_id: string
  retailer_id: number
}> = ({ image_hash_id, retailer_id }) => {
  const { data: retailersHM } = useRetailers()

  const { data: bannerDetails, isLoading } = useBannerDetails(
    image_hash_id,
    retailer_id,
  )

  return (
    <Table
      dataSource={bannerDetails}
      size={'small'}
      loading={isLoading}
      rowKey={(record, index) => `${index}`}
    >
      <Column
        title="Retailer"
        dataIndex="retailer_id"
        key="retailer_id"
        render={(retailer: any) => retailersHM[Number(retailer)].Name}
      />
      <Column
        title="Category"
        dataIndex="category"
        key="category"
        render={(category: any[]) =>
          Array.isArray(category)
            ? category.join('->')
            : 'Cannot parse category'
        }
      />
      <Column
        title="Redirect URL"
        dataIndex="redirect_url"
        key="redirect_url"
        render={(url) => (
          <Button href={url} target="_blank">
            Go to
          </Button>
        )}
      />
      <Column
        title="Screenshot"
        dataIndex="screenshot_image_hash"
        key="screenshot_image"
        render={(url) =>
          url ? (
            <a
              href={`//storage.googleapis.com/di_banners_images/images/${url}.png`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`//storage.googleapis.com/di_banners_images/images/${url}.png`}
                style={{ maxWidth: '350px', height: 'auto' }}
              />
            </a>
          ) : (
            'N/A'
          )
        }
      />
      <Column
        title="Crawl date"
        dataIndex="crawl_date"
        key="crawl_date"
        render={(date) => {
          const timestamp = new Date(date)
          return <p>{moment(timestamp).format('DD/MM/YYYY')}</p>
        }}
      />
      <Column
        title="Search term"
        dataIndex="search_term"
        key="search_term"
        render={(searchTerm: any[]) => <p>{searchTerm ? searchTerm : 'N/A'}</p>}
      />
    </Table>
  )
}

export default BannerDetailsTable
