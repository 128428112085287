import { Button, Pagination, notification } from 'antd'
import api from 'api'
import { Container } from 'components'
import SearchBar from 'components/Banners/SearchBar'
import EmptyState from 'components/EmptyState'
import { BannerStatus } from 'consts'
import { produce } from 'immer'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Banner from '../../components/Banners/Banner'
import ImageZoom from '../../components/ImageZoom'

const StickyContainer = styled(Container)`
  position: sticky;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.75);
  div:nth-child(1) {
    .ant-btn {
      margin-left: 10px;
    }
    span {
      font-size: 1rem;
    }
  }
  div:nth-child(2) {
    .ant-btn:nth-child(1) {
      margin-right: 10px;
    }
  }
`

const BannersContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Restore: React.FC = () => {
  const [banners, setBanners] = useState<any[]>([])
  const [paginatedBanners, setPaginatedBanners] = useState<any[]>([])
  const [bannersPerPage, setBannersPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [restoring, setRestoring] = useState(false)

  const [bannerToZoom, setBannerToZoom] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setPaginatedBanners(
      _.drop(_.values(banners), (currentPage - 1) * bannersPerPage).slice(
        0,
        bannersPerPage,
      ),
    )
  }, [currentPage, banners, bannersPerPage])

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const selectedBanners = useMemo(
    () => _.size(_.filter(banners, (b) => b.selected)),
    [banners],
  )
  const selectedBannersIds = useMemo(() => {
    return banners
      .filter((banner) => banner.selected)
      .map((banner) => banner.image_hash_id)
  }, [banners])
  const handleSelect = (hash: string) => {
    setBanners(
      produce((draft) => {
        const bindex = _.findIndex(banners, (b) => b.image_hash_id === hash)
        draft[bindex].selected = !draft[bindex].selected
      }),
    )
  }

  const handleSelectAll = () => {
    setBanners(
      produce((draft) => {
        _.each(draft, (b) => (b.selected = true))
      }),
    )
  }

  const handleUnselectAll = () => {
    setBanners(
      produce((draft) => {
        _.each(draft, (b) => {
          b.selected = false
        })
      }),
    )
  }

  const handleSearch = async (data: any) => {
    const banners = await api.banners.get({
      ...data,
      status: BannerStatus.CLEANED,
    })

    setBanners(_.map(banners, (b) => ({ ...b, selected: false })))
  }

  const handleRestore = async () => {
    setRestoring(true)
    const _bannerIds = _.map(
      _.filter(banners, (b) => b.selected),
      (b) => b.image_hash_id,
    )
    await api.banners.status(BannerStatus.PENDING, _bannerIds)
    setBanners(_.filter(banners, (b) => !_bannerIds.includes(b.image_hash_id)))
    setRestoring(false)
    notification['success']({
      message: `${_bannerIds.length} banners have been restored`,
    })
  }

  const handleZoom = (banner: any) => {
    setBannerToZoom(banner.imageUrl)
    setModalOpen(true)
  }

  return (
    <>
      <Helmet>
        <title>Restore - Banners - Media management - Dataimpact</title>
      </Helmet>
      <StickyContainer>
        <div>
          <span>{selectedBanners} selected</span>
          <Button type="primary" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button type="primary" onClick={handleUnselectAll}>
            Unselect All
          </Button>
        </div>
        <div>
          <SearchBar onSearch={handleSearch} type="restore" />
          <Button type="primary" loading={restoring} onClick={handleRestore}>
            Restore
          </Button>
        </div>
      </StickyContainer>
      <Pagination
        total={_.size(banners)}
        pageSize={bannersPerPage}
        onShowSizeChange={(current, pageSize) => setBannersPerPage(pageSize)}
        current={currentPage}
        onChange={handlePageChange}
        showSizeChanger={true}
        style={{ textAlign: 'center', marginBottom: '15px' }}
        pageSizeOptions={['10', '20', '50', '100', '1000']}
        size="large"
      />
      <BannersContainer>
        {!paginatedBanners.length ? (
          <EmptyState />
        ) : (
          _.map(paginatedBanners, (b, i) => (
            <Banner
              key={i}
              banner={b}
              onZoom={() => handleZoom(b)}
              onSelect={() => handleSelect(b.image_hash_id)}
              selected={selectedBannersIds.includes(b.image_hash_id)}
              // retailer={retailers[b.retailer_id].Name}
            />
          ))
        )}
      </BannersContainer>
      <Pagination
        total={_.size(banners)}
        pageSize={bannersPerPage}
        onShowSizeChange={(current, pageSize) => setBannersPerPage(pageSize)}
        current={currentPage}
        onChange={handlePageChange}
        showSizeChanger={true}
        style={{ textAlign: 'center', marginBottom: '15px' }}
        pageSizeOptions={['10', '20', '50', '100', '1000']}
        size="large"
      />

      <ImageZoom
        open={modalOpen}
        image={bannerToZoom}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default Restore
