import { Button, Divider, Icon, Select } from 'antd'
import { useFormikContext } from 'formik'
import useClients from 'hooks/useClients'
import React, { useCallback } from 'react'

const { Option } = Select

const ClientsField = () => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<any>()

  const { data: clients, isLoading } = useClients(values.country)
  const selectAllClients = useCallback(() => {
    if (clients?.length) {
      setFieldValue(
        'clients',
        (clients || []).map((c: any) => c.id),
      )
    }
  }, [clients, setFieldValue])

  const unselectAllClients = () => {
    setFieldValue('clients', [])
  }

  return (
    <>
      <label htmlFor="clients">Clients :</label>
      <Select
        showSearch
        id="clients"
        loading={isLoading}
        style={{ maxWidth: 300, width: 300 }}
        placeholder="Select clients"
        optionFilterProp="children"
        size="large"
        mode="multiple"
        maxTagCount={2}
        maxTagPlaceholder={`${values?.clients?.length - 2} more`}
        showArrow={true}
        {...getFieldProps('clients')}
        onBlur={() => void 1}
        onChange={(value: number[]) => setFieldValue('clients', value)}
        dropdownRender={(menu) => (
          <div>
            <div
              style={{
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={selectAllClients}
              >
                <Icon type="plus" /> All Clients
              </Button>
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={unselectAllClients}
              >
                <Icon type="close" /> Unselect all
              </Button>
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        )}
      >
        {(clients || []).map((client: any) => (
          <Option value={client.id} key={client.id}>
            {client.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default ClientsField
