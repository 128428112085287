import { ResponsiveBar } from '@nivo/bar'
import { Col, Divider, Row, Table, Typography } from 'antd'
import api from 'api'
import { Container } from 'components'
import SearchBar from 'components/Banners/SearchBar'
import EmptyState from 'components/EmptyState'
import { useCurrentRequest } from 'contexts/CurrentBannerRequest'
import useRetailers from 'hooks/useRetailers'
import _ from 'lodash'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StickyContainer = styled(Container)`
  position: sticky;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.75);
  div:nth-child(1) {
    .ant-btn {
      margin-left: 10px;
    }
    span {
      font-size: 1rem;
    }
  }
  div:nth-child(2) {
    .ant-btn:nth-child(1) {
      margin-right: 10px;
    }
  }
`

const chartKeys = ['toclean', 'toinsert', 'cleaned', 'inserted']

const getQuery = (retailer: number, period: string[]) =>
  queryString.stringify(
    { retailers: [retailer], period },
    { arrayFormat: 'comma' },
  )

const Dashboard: React.FC = () => {
  const { data: retailers } = useRetailers()
  const [stats, setStats] = useState<any[]>([])

  const { currentRequest } = useCurrentRequest()

  const columns = [
    {
      title: 'Retailer',
      dataIndex: 'retailer',
      key: 'retailer',
      render: (text: any, r: any, i: number) => retailers[text].Name,
    },
    {
      title: 'To clean',
      dataIndex: 'toclean',
      key: 'toclean',
      width: 90,
      render: (text: any, r: any, i: number) => (
        <Link
          to={`/banners/cleaning?${getQuery(
            r.retailer,
            currentRequest.period,
          )}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'To insert',
      dataIndex: 'toinsert',
      key: 'toinsert',
      width: 90,
      render: (text: any, r: any, i: number) => (
        <Link
          to={`/banners/insertion?${getQuery(
            r.retailer,
            currentRequest.period,
          )}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Cleaned',
      dataIndex: 'cleaned',
      key: 'cleaned',
      width: 90,
    },
    {
      title: 'Inserted',
      dataIndex: 'inserted',
      key: 'inserted',
      width: 90,
    },
  ]

  const handleSearch = async (data: any) => {
    const stats = await api.banners.stats(data.retailers, data.period)
    const _stats = _.map(_.groupBy(stats, 'retailer_id'), (v, k) => {
      // TODO: REFACTOR THIS
      const counts = _.mapKeys(v, 'status')
      return {
        retailer: k,
        toclean: counts[0] ? counts[0].count : 0,
        toinsert: counts[1] ? counts[1].count : 0,
        cleaned: counts[2] ? counts[2].count : 0,
        inserted: counts[3] ? counts[3].count : 0,
      }
    })
    setStats(_stats)
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - Banners - Media management - Dataimpact</title>
      </Helmet>

      <StickyContainer>
        <div>
          <SearchBar onSearch={handleSearch} type="dashboard" />
        </div>
        <div />
      </StickyContainer>
      <Container>
        {!stats.length ? (
          <EmptyState />
        ) : (
          <>
            <Typography.Title level={4}>Cleaning/Processing</Typography.Title>
            <Divider />
            <Row gutter={8}>
              <Col span={12}>
                <Table
                  size="small"
                  columns={columns}
                  scroll={{ x: 700 }}
                  dataSource={stats}
                />
              </Col>
              <Col span={12}>
                <div style={{ height: '520px' }}>
                  <ResponsiveBar
                    data={stats}
                    keys={chartKeys}
                    indexBy="retailer"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    colors={{ scheme: 'nivo' }}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Retailer',
                      legendPosition: 'middle',
                      legendOffset: 32,
                      format: (value) => retailers[value].Name,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Items',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 50,
                        itemHeight: 8,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}

export default Dashboard
