import axios, { AxiosRequestConfig } from 'axios'

const redirectToLogin = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  window.location.href = '/login'
}

const interceptFailedRequest = async (error: any) => {
  const originalRequest = error.config
  let refreshToken = localStorage.getItem('refreshToken')

  if (
    refreshToken &&
    error.response.status === 401 &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_MMB_URL}/auth/refresh_token`,
        {
          refreshToken: refreshToken,
        },
        {
          headers: {
            'X-Content-Type-Options': 'nosniff',
          },
        },
      )
      if (res.status === 200) {
        localStorage.setItem('accessToken', res.data.accessToken)
        originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`
        originalRequest.headers['X-Content-Type-Options'] = 'nosniff'
        return axios(originalRequest)
      } else {
        redirectToLogin()
      }
    } catch {
      redirectToLogin()
    }
  }

  return Promise.reject(error)
}

const injectAuthToken = (config: AxiosRequestConfig) => {
  if (config.url === '/auth/login/' || config.url === '/auth/refresh_token/') {
    return config
  }

  config.headers['Authorization'] = `Bearer ${localStorage.getItem(
    'accessToken',
  )}`

  return config
}

const axiosMMB = axios.create({
  baseURL: process.env.REACT_APP_MMB_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
    'Content-Type': 'application/json',
    'X-Content-Type-Options': 'nosniff',
  },
})

axiosMMB.interceptors.response.use((response) => {
  return response
}, interceptFailedRequest)

axiosMMB.interceptors.request.use(injectAuthToken)

// Common business input data
export const retailers = {
  get: (country?: number) =>
    axiosMMB
      .get(`/retailers${country ? `?country=${country}` : ''}`)
      .then((res) => res.data),
}
export const clients = {
  get: (country: number) =>
    axiosMMB.get(`/clients?country=${country}`).then((res) => res.data),
}
export const categories = {
  get: (country: number | string = '') =>
    axiosMMB(`/categories${country ? `?country=${country}` : ''}`).then(
      (res) => res.data,
    ),
  update: (id: string, data: any) =>
    axiosMMB.put(`/categories/${id}`, data).then((res) => res.data),
  insert: (data: any) =>
    axiosMMB.post(`/categories/`, data).then((res) => res.data),
}

export const brandCategory = {
  get: () => axiosMMB(`/brandcategory`).then((res) => res.data),
  predict: (brands: string[]) =>
    axiosMMB.post(`/brandcategory/predict`, { brands }).then((res) => res.data),
  update: (id: number, data: any) =>
    axiosMMB.put(`/brandcategory/${id}`, data).then((res) => res.data),
  insert: (data: any) =>
    axiosMMB.post(`/brandcategory/insert`, data).then((res) => res.data),
}

export const countries = {
  get: () => axiosMMB(`/countries`).then((res) => res.data),
}

export const brands = {
  get: (country: number | null = null) =>
    axiosMMB(`/brands/${country ? `?country=${country}` : ''}`).then(
      (res) => res.data,
    ),
}
export const groups = {
  get: (country?: number) =>
    axiosMMB
      .get(`/groups${country ? `?country=${country}` : ''}`)
      .then((res) => res.data),
}

export const bannerTypes = {
  get: () => axiosMMB(`/bannertypes`).then((res) => res.data),
  update: (id: string, data: any) =>
    axiosMMB.put(`/bannertypes/${id}`, data).then((res) => res.data),
  insert: (data: any) =>
    axiosMMB.post(`/bannertypes/`, data).then((res) => res.data),
}

// cache

export const flushCache = () =>
  axiosMMB.post(`/flush-cache`).then((res) => res.data)

// User auth / management
export const user = {
  subscribe: (id: string) => axiosMMB.post(`/auth/signup`, { id }),

  login: (id: string) => axiosMMB.post(`/auth/login`, { id }),

  logout: (refreshToken: string) =>
    axiosMMB.post('/auth/logout', { refreshToken }),

  get: () => axiosMMB.get(`/users`).then((res) => res.data),

  update: (id: string, roles: number[]) =>
    axiosMMB.put(`/users/${id}`, { roles }).then((res) => res.data),
}

// Banners
export const banner_groups_access = {
  get: () => axiosMMB(`/groupaccess/`).then((res) => res.data),
  update: (id: number, data: any) =>
    axiosMMB.put(`/groupaccess/${id}`, data).then((res) => res.data),
  insert: (data: any) =>
    axiosMMB.post(`/groupaccess/`, data).then((res) => res.data),
}

export const banners = {
  // Status Clean or Insert ( to retrieve status 0 or 1 )
  get: ({ status, ...data }: any) =>
    axiosMMB.post(`/banners/?status=${status}`, data).then((res) => res.data),
  stats: (retailers: number, period: string) =>
    axiosMMB
      .post(`/banners/stats`, { retailers, period })
      .then((res) => res.data),
  status: (status: number, banners: string[]) =>
    axiosMMB
      .put(`/banners/status`, { status, banners })
      .then((res) => res.data),
  insert: (data: any) =>
    axiosMMB.put(`/banners/insert`, data).then((res) => res.data),
  details: (image_hash_id: string, retailer_id: number) =>
    axiosMMB
      .post(`/banners/${image_hash_id}/details?retailer_id=${retailer_id}`)
      .then((res) => res.data),
  products: (image_hash_id: string, retailer_id: number) =>
    axiosMMB
      .post(`/banners/${image_hash_id}/products?retailer_id=${retailer_id}`)
      .then((res) => res.data),
}

export const userActions = {
  get: (data: any) =>
    axiosMMB.post(`/useractions/`, data).then((res) => res.data),
}

export const diCategories = {
  get: (country: number) =>
    axiosMMB(`/di_categories?country=${country}`).then((res) => res.data),
}
export const categoriesMapping = {
  get: (data: any) =>
    axiosMMB(
      `/categories_mapping?country=${data.country}&newCatDI=${data.newCatDI}`,
      data,
    ).then((res) => res.data),
  update: (data: any) =>
    axiosMMB.put(`/categories_mapping`, data).then((res) => res.data),
}
export const groupsBrands = {
  get: (query: any) => {
    const queryParams = new URLSearchParams(query).toString()
    return axiosMMB(`/group_brand?${queryParams}`).then((res) => res.data)
  },
  update: (data: any) => {
    const { image_hash_id, ...payload } = data
    return axiosMMB
      .put(`/group_brand?image_hash_id=${image_hash_id}`, payload)
      .then((res) => res.data)
  },
}

const api = {
  user,
  banners,
  banner_groups_access,
  userActions,
  groups,
  brands,
  countries,
  categories,
  retailers,
  clients,
  brandCategory,
  groupsBrands,
  bannerTypes,
  flushCache,
  diCategories,
  categoriesMapping,
}
export default api
