import { Alert, Button, Drawer } from 'antd'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { BannerStatus } from 'consts'
import { useCurrentRequest } from 'contexts/CurrentBannerRequest'
import { Form, Formik } from 'formik'
import { Moment } from 'moment'
import React, { Suspense, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import ClientsField from './SearchBar/ClientsField'
import Container from './SearchBar/Container'
import CountryField from './SearchBar/CountryField'
import HashField from './SearchBar/HashField'
import InsertedFileds from './SearchBar/InsertedFileds'
import PeriodField from './SearchBar/PeriodField'
import PurgedField from './SearchBar/PurgedField'
import RetailersField from './SearchBar/RetailersField'
import StatusField from './SearchBar/StatusField'
import TypeField from './SearchBar/TypeField'

type FormValues = {
  country: number | undefined
  period: RangePickerValue
  retailers: number[]
  status: number
  type: number
  format: string | null
  categories: string[]
  excludeCategories: boolean
  brands: string[]
  excludeBrands: boolean
  groups: string[]
  excludeGroups: boolean
  is_promotion: number | null
  is_purged: number | null
  is_game: number | null
  hash: string
  clients: number[]
}

const initialValues: FormValues = {
  country: undefined,
  period: [],
  retailers: [],
  status: 1,
  type: 0,
  format: null,
  categories: [],
  excludeCategories: false,
  brands: [],
  excludeBrands: false,
  groups: [],
  excludeGroups: false,
  is_promotion: 0,
  is_purged: 0,
  is_game: 0,
  hash: '',
  clients: [],
}

const formValidatationSchema = Yup.object().shape({
  // retailers: Yup.array().of(Yup.number()).min(1),
  // period: Yup.array().of(Yup.object()).min(2),
})

const queryStringToObject = (searchParams: any) => {
  const keys = Array.from(searchParams.keys())
  const queryParams = keys.reduce((acc: Record<string, any>, key: any) => {
    if (key === 'country') {
      acc[key] = +searchParams.get(key)
    } else if (key === 'hash') {
      acc[key] = searchParams.get(key)
    } else if (key === 'retailer_id') {
      acc['retailers'] = [+searchParams.get(key)]
    }
    return acc
  }, {})
  if (keys.length) {
    queryParams['status'] = BannerStatus.INSERTED
  }
  return queryParams
}

const SearchBar: React.FC<{
  onSearch: (data: any) => Promise<any>
  type?: string
}> = ({ onSearch, type }) => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(true)
  const { setCurrentRequest } = useCurrentRequest()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const queryParams = queryStringToObject(searchParams)

  const handleSearch = async (values: FormValues) => {
    const _values = {
      ...values,
      period: (values.period as []).map((p: Moment) => p.format('YYYY-MM-DD')),
    }
    setCurrentRequest(_values)
    await onSearch(_values)
    setDrawerVisible(false)
  }

  const handleOpen = () => {
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={handleOpen} icon="search">
        Display Search
      </Button>
      <Drawer
        title="Filters"
        placement="left"
        width={360}
        onClose={onClose}
        visible={drawerVisible}
      >
        <Formik
          initialValues={{ ...initialValues, ...queryParams }}
          validationSchema={formValidatationSchema}
          onSubmit={handleSearch}
        >
          {({ values, isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <Container>
                  <CountryField />
                  <ClientsField />
                  <RetailersField />
                  {type === 'restore' && <PurgedField />}
                  {type !== 'dashboard' && <TypeField />}
                  {type === 'insertion' && <StatusField />}
                  <Suspense fallback={<p>Loading...</p>}>
                    {[
                      BannerStatus.INSERTED,
                      BannerStatus.READY_TO_BE_INSERTED,
                    ].includes(values.status) &&
                      (values.country ? (
                        <InsertedFileds />
                      ) : (
                        <Alert message="Please select a country" type="error" />
                      ))}
                  </Suspense>
                  <PeriodField />
                  {type === 'insertion' && <HashField />}
                  <Button
                    type="primary"
                    size="large"
                    icon="search"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                  >
                    Search
                  </Button>
                </Container>
              </Form>
            )
          }}
        </Formik>
      </Drawer>
    </>
  )
}

export default SearchBar
