import api from 'api'
import { useQuery } from 'react-query'

const useBannerDetails = (image_hash_id: string, retailer_id: number) => {
  return useQuery({
    queryKey: ['details', image_hash_id, retailer_id],
    queryFn: async () => {
      const data = await api.banners.details(image_hash_id, retailer_id)
      return data
    },
  })
}

export default useBannerDetails
