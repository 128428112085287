import { Select } from 'antd'
import { useFormikContext } from 'formik'
import React from 'react'

const { Option } = Select

const PurgedField = () => {
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="type">Automatic cleaned: </label>
      <Select
        {...formik.getFieldProps('is_purged')}
        onBlur={() => void 1}
        id="type"
        style={{ width: 300 }}
        onChange={(value: number) => formik.setFieldValue('is_purged', value)}
        size="large"
      >
        <Option value={0}>No</Option>
        <Option value={1}>Yes</Option>
      </Select>
    </>
  )
}

export default PurgedField
