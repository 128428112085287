import { Table } from 'antd'
import useBannerProducts from 'hooks/useBannerProducts'
import React from 'react'

const { Column } = Table

const BannerProductsTable: React.FC<{
  image_hash_id: string
  retailer_id: number
}> = ({ image_hash_id, retailer_id }) => {
  const { data: bannerDetails, isLoading } = useBannerProducts(
    image_hash_id,
    retailer_id,
  )

  return (
    <Table
      dataSource={bannerDetails}
      size={'small'}
      loading={isLoading}
      rowKey={(record, index) => `${index}`}
    >
      <Column title="Name" dataIndex="product_name" key="product_name" />
      <Column title="EAN" dataIndex="product_ean" key="product_ean" />
      <Column title="Ref pe" dataIndex="product_id" key="product_id" />
      <Column title="RPC" dataIndex="rpc" key="rpc" />
      <Column
        title="Image"
        dataIndex="product_image"
        key="screenshot_image"
        render={(url) =>
          url ? (
            <a href={url} target="_blank">
              <img src={url} style={{ maxWidth: '350px', height: 'auto' }} />
            </a>
          ) : (
            'N/A'
          )
        }
      />
    </Table>
  )
}

export default BannerProductsTable
