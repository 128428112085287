import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { useOnClickOutside } from 'usehooks-ts'
import DropdownToggle from './DropdownToggle'
import SearchInput from './SearchInput'
import SelectedOption from './SelectedOption'

export interface Option {
  id: string
  name: string
}

const ClearButton = styled.span`
  margin-right: 5px;
  padding: 2px;
  &:hover {
    color: #d0021b;
  }
`

const Container = styled.div`
  width: 100%;
`

const Option = styled.div<{ highlighted: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.highlighted ? 'rgba(0, 126, 255, 0.1)' : 'white'};
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 126, 255, 0.1);
  }
`

const ITEM_HEIGHT = 40
const VISIBLE_ITEMS = 5

const SelectComponent = ({
  options,
  value,
  onChange,
  autoFocus,
  tabIndex,
}: {
  options: Option[]
  value: string[]
  onChange: (options: string[]) => void
  autoFocus?: boolean
  tabIndex?: number
}) => {
  const selectRef = useRef(null)
  const searchInputRef = useRef<HTMLInputElement>()
  const listRef = useRef<List>(null)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [highlightedOption, setHighlightedOption] = useState<number>(0)

  const handleOptionClick = (option: Option) => {
    onChange([...value, option.id])
    setIsDropdownOpen(false)
    setSearchTerm('')
    searchInputRef.current?.focus()
  }

  const handleOptionRemove = (option: Option) => {
    onChange(value.filter((o) => o !== option.id))
  }

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          !value.includes(option.id),
      ),
    [options, searchTerm, value],
  )

  useEffect(() => {
    if (filteredOptions.length) {
      setHighlightedOption(0)
    }
  }, [filteredOptions])

  const handleClickOutside = () => {
    setIsDropdownOpen(false)
  }

  const handleRemoveElement = () => {
    if (value.length) {
      const _value = [...value]
      _value.pop()
      onChange(_value)
    }
  }

  useOnClickOutside(selectRef, handleClickOutside)

  const Row = ({ index, style }: any) => {
    const option = filteredOptions[index]
    return (
      <Option
        onClick={() => handleOptionClick(option)}
        style={style}
        highlighted={highlightedOption === index}
      >
        {option.name}
      </Option>
    )
  }

  const handleNavigation = (top: boolean) => {
    if (top && highlightedOption !== 0) {
      setHighlightedOption((hp) => hp - 1)
    } else if (!top && highlightedOption < filteredOptions.length - 1) {
      setHighlightedOption((hp) => hp + 1)
    }
  }

  const handleSubmit = () => {
    if (filteredOptions[highlightedOption]) {
      handleOptionClick(filteredOptions[highlightedOption])
    }
  }

  useEffect(() => {
    listRef.current?.scrollToItem(highlightedOption, 'center')
  }, [highlightedOption])

  return (
    <Container ref={selectRef}>
      <DropdownToggle
        onClick={(e) => {
          e.stopPropagation()
          setIsDropdownOpen(!isDropdownOpen)
        }}
      >
        {options
          .filter((x) => value.includes(x.id))
          .map((option) => (
            <SelectedOption
              key={option.id}
              option={option}
              onClick={() => handleOptionRemove(option)}
            />
          ))}
        <span style={{ position: 'absolute', top: 0, right: '6px' }}>
          {value.length > 0 && (
            <ClearButton
              onClick={(e) => {
                e.stopPropagation()
                onChange([])
              }}
            >
              ✖
            </ClearButton>
          )}
          <span>{isDropdownOpen ? '▲' : '▼'}</span>
        </span>
        <SearchInput
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          ref={searchInputRef as any}
          onSearchClick={(open) => setIsDropdownOpen(open)}
          onRemoveElement={handleRemoveElement}
          value={searchTerm}
          onChange={(value) => {
            setSearchTerm(value)
            if (!isDropdownOpen) {
              setIsDropdownOpen(true)
            }
          }}
          onNavigate={handleNavigation}
          onSubmit={handleSubmit}
        />
      </DropdownToggle>

      {isDropdownOpen && (
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginTop: '5px',
            position: 'absolute',
            width: '100%',
            backgroundColor: 'white',
            zIndex: 1,
            height: `${ITEM_HEIGHT * VISIBLE_ITEMS}px`,
            overflow: 'hidden',
          }}
        >
          <List
            height={ITEM_HEIGHT * VISIBLE_ITEMS}
            itemCount={filteredOptions.length}
            itemSize={ITEM_HEIGHT}
            width="100%"
            ref={listRef}
          >
            {Row}
          </List>
        </div>
      )}
    </Container>
  )
}

export default SelectComponent
