import { Select } from 'antd'
import { useFormikContext } from 'formik'
import useCountries from 'hooks/useCountries'
import { map } from 'lodash'
import React from 'react'

const { Option } = Select

const CountryField = () => {
  const { data: countries } = useCountries()
  const formik: any = useFormikContext()

  return (
    <>
      <label htmlFor="country">Country :</label>
      <Select
        showSearch
        id="country"
        style={{ maxWidth: 300, width: 300 }}
        placeholder="Select country"
        optionFilterProp="children"
        size="large"
        {...formik.getFieldProps('country')}
        onBlur={() => void 1}
        onChange={(value: number) => {
          formik.setFieldValue('country', value)
          formik.setFieldValue('brands', [])
          formik.setFieldValue('groups', [])
        }}
      >
        {map(countries, (country: any) => (
          <Option value={country.value} key={country.value}>
            {country.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default CountryField
