import { Button, Divider, Icon, Select } from 'antd'
import { useFormikContext } from 'formik'
import useRetailers from 'hooks/useRetailers'
import { map } from 'lodash'
import React, { useCallback, useEffect } from 'react'

const { Option } = Select

const RetailersField = () => {
  const { values, setFieldValue, getFieldProps }: any = useFormikContext()

  const { data: retailers, isLoading } = useRetailers(
    values.country,
    ['by-country'],
    !!values.country,
  )
  const selectAllRetailers = useCallback(() => {
    setFieldValue(
      'retailers',
      map(retailers, (r: any) => r.Id),
    )
  }, [retailers, setFieldValue])

  const unselectAllRetailers = useCallback(() => {
    setFieldValue('retailers', [])
  }, [setFieldValue])

  useEffect(() => {
    selectAllRetailers()
  }, [selectAllRetailers])

  return (
    <>
      <label htmlFor="retailers">Retailers :</label>
      <Select
        showSearch
        id="retailers"
        style={{ maxWidth: 300, width: 300 }}
        placeholder="Select retailers"
        optionFilterProp="children"
        size="large"
        loading={isLoading}
        mode="multiple"
        maxTagCount={2}
        maxTagPlaceholder={`${values.retailers.length - 2} more`}
        showArrow={true}
        {...getFieldProps('retailers')}
        onBlur={() => void 1}
        onChange={(value: number[]) => setFieldValue('retailers', value)}
        dropdownRender={(menu) => (
          <div>
            <div
              style={{
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={selectAllRetailers}
              >
                <Icon type="plus" /> All Retailers
              </Button>
              <Button
                type="link"
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={unselectAllRetailers}
              >
                <Icon type="close" /> Unselect all
              </Button>
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        )}
      >
        {map(retailers, (retailer: any) => (
          <Option value={retailer.Id} key={retailer.Id}>
            {retailer.Name}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default RetailersField
