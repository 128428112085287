import { Button, Divider, notification } from 'antd'
import api from 'api'
import BannersList from 'components/Banners/BannersList'
import InsertionDrawerWrapper from 'components/Banners/Insertion/InsertionDrawer/InsertionDrawer'
import SearchBar from 'components/Banners/SearchBar'
import { BannerStatus } from 'consts'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Banner, Banner as TBanner } from 'types'
import flattenGroups from 'utils/flattenGroups'
import { BannersState, useActiveBannerStore, useBannersStore } from './store'

const Insertion: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const setBanners = useBannersStore((state: BannersState) => state.setBanners)
  const updateBanner = useBannersStore(
    (state: BannersState) => state.updateBanner,
  )

  const removeBanner = useBannersStore(
    (state: BannersState) => state.removeBanner,
  )

  const setActiveBannerIds = useActiveBannerStore(
    (state) => state.setActiveBannerIds,
  )

  const banners = useBannersStore((state) => state.banners)

  const selectedBannersIds = useBannersStore(
    (state) => state.selectedBannersIds,
  )

  const setSelectedBannersIds = useBannersStore(
    (state) => state.setSelectedBannersIds,
  )

  const handleInsert = useCallback(
    async (data: Banner[] & { currentStatus: number }) => {
      const fields = data.map((d) => {
        const { id, status, ...rest } = d
        return rest
      })

      try {
        await api.banners.insert(fields)

        if (data.currentStatus === BannerStatus.INSERTED) {
          updateBanner(data)
        } else {
          removeBanner(data)
        }
        setSelectedBannersIds([])
        notification['success']({
          message: `Banner inserted successfully`,
        })
      } catch (error: any) {
        notification['error']({
          message: error.response.data.message,
        })
      }
    },
    [removeBanner, setSelectedBannersIds, updateBanner],
  )
  const handleSearch = async (data: any) => {
    const banners: TBanner[] = await api.banners.get(data)

    setBanners &&
      setBanners(
        _.map(banners, (b) => ({
          ...b,
          groups_brands: b.groups_brands ? flattenGroups(b.groups_brands) : [],
          selected: false,
        })),
      )
  }

  const handleClick = useCallback(
    (bannersIds: string[]) => {
      setDrawerOpen(true)
      setActiveBannerIds(bannersIds)
    },
    [setActiveBannerIds],
  )

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false)
    setActiveBannerIds([])
    setSelectedBannersIds([])
  }, [setActiveBannerIds, setSelectedBannersIds])

  return (
    <>
      <Helmet>
        <title>Insertion - Banners - Media management - Dataimpact</title>
      </Helmet>

      <BannersList
        allowNavigation={!drawerOpen}
        showSelectAll
        onEdit={handleClick}
      >
        <Divider type="vertical" />
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          <Button
            type="default"
            icon="edit"
            disabled={!selectedBannersIds.length}
            onClick={() => handleClick(selectedBannersIds)}
          >
            Insert
          </Button>
          <SearchBar onSearch={handleSearch} type="insertion" />
        </div>
      </BannersList>
      {banners.length && drawerOpen && (
        <InsertionDrawerWrapper
          open={drawerOpen}
          onClose={handleDrawerClose}
          onInsert={handleInsert}
        />
      )}
    </>
  )
}

export default Insertion
