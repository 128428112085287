import api from 'api'
import { useQuery } from 'react-query'

const useClients = (country: number) => {
  return useQuery({
    queryKey: ['clients', country],
    queryFn: async () => {
      const data = await api.clients.get(country)
      return data
    },
    enabled: !!country,
  })
}

export default useClients
